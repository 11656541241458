<template>
    <div>
        <div>
            　　<input type="checkbox" @click="checkAll" v-model="checked">
            　　<span> 全选</span>
        </div>
        <ul>　　
            <li v-for="(item,index) in list" :key="index" style="margin-top:20px;">
                　　　　<input type="checkbox" v-model="checkModel" :value="item.id">
                　　　　<span>{{item.name}}--</span>
                　　　　<span>{{item.age}}--</span>
                　　　　<span>{{item.money}}元</span>　
            </li>
        </ul>

    </div>

</template>

<script>
    export default {
        data() {
            return {
                list: [
                    {id: 1, name: "明明", age: 23, money: 100},
                    {id: 2, name: "红红", age: 18, money: 200},
                    {id: 3, name: "强强", age: 29, money: 300}
                ],
                checked: false,
                checkModel: []

            };
        },
        watch: {
            checkModel() {
                if (this.checkModel.length == this.list.length) {
                    this.checked = true;
                } else {
                    this.checked = false;
                }
            }

        },
        mounted() {
        },
        methods: {
            checkAll() {
                if (this.checked) {
                    this.checkModel = [];
                } else {
                    this.list.forEach((item) => {
                        if (this.checkModel.indexOf(item.id) == -1) {
                            this.checkModel.push(item.id)
                        }
                    })
                }
            }
        }
    };
</script>

<style>


</style>
